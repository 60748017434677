@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box !important;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 20mm;
}

:root {
    --primary: #3c358a;
    --primary-color: #3c358a;
    --primary-dark: #231f55;
    --primary-color-dark: #231f55;
}

body {
    margin: 0;
    font-family: Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    background: linear-gradient(
        180deg,
        var(--primary-dark) 0%,
        var(--primary-dark) 100%
    );
    --side-bar-width: 15rem;
}

label {
    margin: unset;
}

a {
    color: var(--primary);
}

a:hover,
a:focus {
    color: var(--primary-dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

.link-button {
    text-decoration: none;
}

#root {
    display: flex;
    flex-direction: column;
}

.modal__overlay,
.react-confirm-alert-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.modal {
    width: 500px;
    background: white;
    border-radius: 5px;
    padding: 40px;
    outline: none;
    border: none;
    z-index: 5;
    max-height: 100vh;
    overflow: auto;
}

.select__menu {
    border-radius: 0.5rem !important;
    border: 1px solid #bfbfbf;
    box-shadow: none !important;
}

.select__options {
    background: white;
    border: 1px solid black;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 4;
}

.select__option {
    font-size: 0.75rem !important;
}

/* .select__option--is-focused {
    @apply !bg-gray-100 !text-black !font-bold;
} */
